import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const Copyright = () => {
    return (
        <div className="copyright-area copyright-style-one">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="copyright-left">
                            <ul className="ft-menu link-hover">
                                <li>
                                    <Link to="/privacy-policy"> Privacy Policy </Link> 
                                </li>
                                <li>
                                    <Link to="/terms-condition"> Terms And Condition </Link> 
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="copyright-right text-center text-md-end">
                            <p className="copyright-text">
                                Creative and Inspiration with us. Copyright © 2021 - {new Date().getFullYear()} 
                                <Link to="/"> <strong>Code Aims</strong></Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <a className='whatsapp' href="https://wa.me/message/W4S7AXOJVDK5O1?src=qr" target='_blank'>
                <img src='/images/whatsapp-icon.png' alt='whatsapp Code Aims Support' loading="lazy"/>
            </a>
        </div>
    )
}
export default Copyright;   