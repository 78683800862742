import React from 'react';

const slidesData = [
    { img: '/images/land-design/abwtrade.png', alt: 'codeaims-abwtrade' },
    { img: '/images/land-design/al-mustafa-acadmey.png', alt: 'codeaims-al-mustafa-acadmey' },
    { img: '/images/land-design/alex.png', alt: 'codeaims-alex' },
    { img: '/images/land-design/B-S-Pro cleaning.png', alt: 'codeaims-B-S-ProCleaning' },
    { img: '/images/land-design/bocoh-studios.png', alt: 'codeaims-bocoh-studios' },
    { img: '/images/land-design/bocohstudios.png', alt: 'codeaims-bocohstudios' },
    { img: '/images/land-design/build.png', alt: 'codeaims-build' },
    { img: '/images/land-design/chabook.png', alt: 'codeaims-chabook' },
    { img: '/images/land-design/Clithro-mosque.png', alt: 'codeaims-Clithro-mosque' },
    { img: '/images/land-design/The-adseagle.png', alt: 'codeaims-The-adseagle' },
];

const slidesData1 = [
    { img: '/images/land-design/Delta-wraps.png', alt: 'codeaims-Delta-wraps' },
    { img: '/images/land-design/Ezimetal.png', alt: 'codeaims-Ezimetal' },
    { img: '/images/land-design/Fix-ur-crack.png', alt: 'codeaims-Fix-ur-crack' },
    { img: '/images/land-design/gadget-recycle.png', alt: 'codeaims-gadget-recycle' },
    { img: '/images/land-design/Glocester.png', alt: 'codeaims-Glocester' },
    { img: '/images/land-design/Gsm-mobile.png', alt: 'codeaims-Gsm-mobile' },
    { img: '/images/land-design/hotspot.png', alt: 'codeaims-hotspot' },
    { img: '/images/land-design/ibrahim-memon.png', alt: 'codeaims-ibrahim-memon' },
    { img: '/images/land-design/localhost.png', alt: 'codeaims-localhost' },
    { img: '/images/land-design/Visa-guide.png', alt: 'codeaims-Visa-guide' },
    { img: '/images/land-design/Tech-fix.png', alt: 'codeaims-Tech-fix' },
];


const slidesData2 = [
    { img: '/images/land-design/Mega-phone.png', alt: 'codeaims-Mega-phone' },
    { img: '/images/land-design/ifix.png', alt: 'codeaims-ifix' },
    { img: '/images/land-design/Melbourne-ppc.png', alt: 'codeaims-Melbourne-ppc' },
    { img: '/images/land-design/mobile-doctor.png', alt: 'codeaims-mobile-doctor' },
    { img: '/images/land-design/Methewsins.png', alt: 'codeaims-Methewsins' },
    { img: '/images/land-design/mr-mrs.png', alt: 'codeaims-mr-mrs' },
    { img: '/images/land-design/Narjisvogue.png', alt: 'codeaims-Narjisvogue' },
    { img: '/images/land-design/nasa.png', alt: 'codeaims-nasa' },
    { img: '/images/land-design/Nft-marketplace.png', alt: 'codeaims-Nft-marketplace' },
];

const slidesData3 = [
    { img: '/images/land-design/Persian-delight.png', alt: 'codeaims-Persian-delight' },
    { img: '/images/land-design/Personal.png', alt: 'codeaims-Personal' },
    { img: '/images/land-design/Phone-doctor.png', alt: 'codeaims-Phone-doctor' },
    { img: '/images/land-design/Pressure-watching.png', alt: 'codeaims-Pressure-watching' },
    { img: '/images/land-design/real-estate.png', alt: 'codeaims-real-estate' },
    { img: '/images/land-design/sea-water.png', alt: 'codeaims-sea-water' },
    { img: '/images/land-design/shamily-business.png', alt: 'codeaims-shamily-business' },
    { img: '/images/land-design/SJ-design.png', alt: 'codeaims-SJ-design' },
];

const PortfolioDesign = () => {
    return (
        <>
            <div className="design-portfolio">
                <div className='column'>
                    {slidesData.map((slide, index) => (
                        <img
                        src={slide.img}
                        alt={slide.alt}
                        key={index}
                        />
                    ))}
                </div>
                <div className='column'>
                    {slidesData1.map((slide, index) => (
                        <img
                        src={slide.img}
                        alt={slide.alt}
                        key={index}
                        />
                    ))}
                </div>
                <div className='column'>
                    {slidesData2.map((slide, index) => (
                        <img
                        src={slide.img}
                        alt={slide.alt}
                        key={index}
                        />
                    ))}
                </div>
                <div className='column'>
                    {slidesData3.map((slide, index) => (
                        <img
                        src={slide.img}
                        alt={slide.alt}
                        key={index}
                        />
                    ))}
                </div>
            </div>
        </>
    )
}
export default PortfolioDesign;