import React from 'react';
import { FaYoutube } from 'react-icons/fa';
import { FiFacebook, FiInstagram, FiChevronRight } from "react-icons/fi";
import { Link } from 'react-router-dom';
import { MdEmail } from "react-icons/md";
import { FaGithub } from "react-icons/fa";
import { FaCodeBranch } from "react-icons/fa";

const HeaderTopBar = () => {
    return (
        <div className="header-top-bar">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-12 col-12">
                        <div className="header-left">
                            <Link to="/portfolio"> See Our Lastest Works <FiChevronRight /> </Link>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12 col-12">
                        <div className="header-right">
                            <div className="address-content">
                                <p> 
                                    <MdEmail /> 
                                    <span> <a href="mailto:info@codeaims.com">info@codeaims.com</a> </span>
                                </p>
                                <p> 
                                    <FaCodeBranch />
                                    <span> <a href="https://wa.me/message/W4S7AXOJVDK5O1?src=qr" target="_blank" rel="noreferrer"> Let's Talk </a> </span>
                                </p>
                            </div>
                            <div className="social-icon-wrapper">
                                <ul className="social-icon social-default icon-naked">
                                    <li><a href="https://facebook.com/codeaims" target='_blank' rel="noreferrer" aria-label="Read more About Code Aims in Facebook"><FiFacebook /></a></li>
                                    <li><a href="https://instagram.com/codeaims" target='_blank' rel="noreferrer" aria-label="Read more About Code Aims in Instagram"><FiInstagram /></a></li>
                                    <li><a href="https://youtube.com/@Code_Aims" target='_blank' rel="noreferrer" aria-label="Read more About Code Aims in Youtube"><FaYoutube /></a></li>
                                    <li><a href="https://github.com/codeaims" target='_blank' rel="noreferrer" aria-label="Connect with Code Aims in Whatsapp"><FaGithub />

                                    </a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopBar;
