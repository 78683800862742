import {useState} from "react";
import { FiX} from "react-icons/fi";

const HeaderTopNews = () => {
    const [deactive, setClass] = useState('');
    return (
        <div className={`header-top-news bg_image ${deactive}`}  style={{ padding: '10px 0px', backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/top-banner.webp)`}} >
            <div className="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner">
                                <div className="content">
                                    
                                    <span className="news-text"> Exclusive Discount 🚀 Hurry! Limited-Time 30% Discount –<strong> Act Fast! </strong></span>
                                </div>
                                <div className="right-button">
                                    <a rel="noreferrer" target="_blank" href="https://wa.me/message/W4S7AXOJVDK5O1?src=qr">
                                        <span className="rn-badge"> 
                                            Let’s Build Your Future – Start Today! 
                                        </span>
                                        {/* <span> Exclusive Discount – Claim Now! <FiArrowRight /></span> */}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="icon-close">
                <button className="close-button" onClick={() => setClass('deactive')}><FiX /></button>
            </div>
        </div>
    )
}

export default HeaderTopNews;
