import PropTypes from "prop-types";
import React from 'react';
import {Link} from "react-router-dom";

const Logo = ({image, image2}) => {
    return(
        <div className="logo">
            <Link to={"/"}>
                <img className="logo-light" src={`/images/logo/logo.webp`} data-src={image} alt="Affordable Web Design & Development with Code Aims light logo" loading="lazy"/>
                <img className="logo-dark" src={image2} data-src={image2} alt="Affordable Web Design & Development with Code Aims dark logo" loading="lazy"/>
            </Link>
        </div>
    )
};

Logo.propTypes = {
    image: PropTypes.string.isRequired,
    image2: PropTypes.string.isRequired,
};

export default Logo;