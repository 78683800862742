import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from '../elements/separator/Separator';

import { FiArrowRight } from 'react-icons/fi';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import AdvanceService from '../elements/advancetab/AdvanceService';
import ContactForm from '../elements/contact/ContactForm';
import HeaderTopBar from '../common/header/HeaderTopBar';
import AccordionTwo from '../elements/accordion/AccordionTwo';
import ServiceMain from '../elements/service/ServiceMain';
import SlipThree from '../elements/split/SlipThree';
import SlipFour from '../elements/split/SlipFour';
import ContactFormService from '../elements/contact/ContactFormService';

const Data = [
    {
        title : <><strong>WordPress</strong> Development</>,
        description: 
        <>
            <h4 className="sub-title">Transform Your Business with <strong>Expert WordPress Specialists.</strong></h4>
            <p className=''><strong>WordPress</strong> is the world's leading platform for building dynamic, scalable websites. By choosing WordPress, we provide our clients with robust, highly customizable websites tailored to meet their exact needs.</p><p className=''>We leverage WordPress as both a powerful framework and content management system, but that doesn’t mean your site will look generic. Our team specializes in <strong>customizing, developing, and extending the WordPress framework to create truly unique</strong>, bespoke websites.</p><p className=''>Many of our clients come to us with existing WordPress sites that need modifications. Whether they're dealing with off-the-shelf themes that aren't delivering the desired results or they've started customizing their site but hit a roadblock, we offer <strong>professional solutions</strong> to overcome these challenges and deliver the website they've always envisioned.</p>
        </>,
        image: './images/split/wordpress-development1.jpg',
        btnText: 'Start a project',
    },
    {
        title : <><strong>Digital</strong> Art</>,
        description: 
        <>
            <h4 className="sub-title">Transform ideas into  captivating visuals with Code Aims' <strong>expert digital art services. </strong> </h4>
            <p className=''>Digital art is any artwork that draws upon digital technology as an essential part of its creative process. It encompasses a wide range of techniques, from digital drawings, paintings, 2D designs, animations, graphic art, and illustration, to photos, videos, and even sculpture.</p><p className=''><strong>Digital Art Services:</strong> </p>
            <ul> <li> <strong>Custom Designs:</strong>  Tailored digital artwork that reflects your unique ideas and vision.</li>  <li><strong>High-Quality Creations</strong> : Stunning visuals crafted with the latest tools and techniques for professional results.</li> <li><strong> Versatile Styles:</strong> We specialize in various digital art styles, including 2D, concept art, illustrations, and more.
            </li>  <li><strong> Creative Illustrations:</strong>  Bringing your concepts to life with vivid details and imaginative designs.</li> <li><strong> Timely Delivery:</strong>  Quick turnaround with high-quality artwork, meeting your deadlines without compromising on creativity.
            </li>  </ul>
        </>,
        image: './images/split/digital-art.jpg',
    },
    {
        title : <><strong>2d Character</strong> & Enviroment Design</>,
        description: 
        <>
            <h4 className="sub-title">Bringing Your Ideas to Life with <strong> Vivid Detail and Creativity</strong></h4>
            <p className=''>At Code Aims, we specialize in 2D character and environment design services that transform your concepts into visually stunning works of art. Our team of skilled designers works closely with you to understand your vision and bring it to life through vibrant, detailed, and imaginative designs. </p><p className=''>Whether you're looking for unique characters for your game, animation, or branding, or immersive environments that set the tone for your project, we ensure that every detail is meticulously crafted to match your expectations.</p><p className=''><strong>Our 2D Character & Environment Design Services Include:</strong> </p>
            <ul> <li> <strong>Custom Character Design:</strong>  Creating distinctive, expressive characters that fit your brand or project’s narrative.
            </li>  <li><strong>Environment Design:</strong>  Designing immersive, creative environments that complement the story and atmosphere of your project.
            </li> <li><strong>Concept Art: </strong>  Providing detailed visual concepts that help you visualize the final outcome before production begins. </li> </ul>
        </>,
        image: './images/split/character-design.jpg',
    },
    {
        title : <>Security <strong>Maintenance</strong></>,
        description: 
        <>
            <h4 className="sub-title"><strong>Website Management Services</strong> That Save You Time, Stress, and Money.</h4>
            <p className=''>Running your website shouldn’t be a hassle. We understand that maintaining your site, adding fresh content, and troubleshooting issues can be time-consuming and distracting. As a business owner, you'd much rather focus on growing your business</p><p className=''>That’s where our <strong>Website Management Service</strong> comes in. For a simple monthly or yearly fee, we handle all the technical details—keeping your website secure, up-to-date, and performing at its best.</p><p className=''><strong>Why Choose Our Website Management Service?</strong> </p>
            <ul> <li> <strong>Hassle-Free:</strong> We take care of everything from updates to security, so you don’t have to worry about technical details.</li>  <li><strong>Time-Saving:</strong> Free up your time to focus on what really matters—running and growing your business.</li> <li><strong>Professional Care: </strong> Our expert team ensures your website is always performing at its peak, without you having to lift a finger.</li> </ul>
        </>,
        image: './images/split/security-maintenance.jpg',
    },
    {
        title : <><strong>Migration</strong></>,
        description: 
        <>
            <h4 className="sub-title">Seamless<strong> Website Migration </strong> with Minimal Disruption</h4>
            <p className=''>Changing your hosting provider or migrating your website shouldn’t be a stressful experience. It should be quick, hassle-free, and seamless — with no downtime or disruption to your business operations. At Sanders Web Works, we specialize in making website migrations smooth and efficient, ensuring your site continues to run without any interruptions.</p><p className=''>With extensive experience in successfully migrating websites, we’ve saved our clients time, effort, and the headache of complex technical challenges. From start to finish, we handle all the details, including communication with your new hosting provider and managing the entire process for you.</p><p className=''><strong>Why Choose Sanders Web Works for Website Migration?</strong> </p>
            <ul> <li> <strong>No Disruptions:</strong> We ensure a smooth migration process with minimal to no downtime.</li>  <li><strong>Expertise You Can Rely On:</strong> With years of experience in website migrations, we’ve perfected the process to make it as seamless as possible.</li> <li><strong>Full Support: </strong> We provide ongoing website management, so you can count on us for continued support after your migration is complete.</li> </ul>
        </>,
        image: './images/split/web-migration.jpg',
    },
];

const Services = () => {
    return (
        <>
            <SEO title="Affordable Code Aims Services" />
                <main className="page-wrapper">
                    <HeaderTopBar HeaderSTyle="header-transparent"/>
                    <HeaderOne btnStyle=" round btn-icon" HeaderSTyle="header-transparent pt--20" />

                    <div className="slider-area slider-style-6 shape-right height-1020" style={{backgroundImage: `url(/images/bg/service-banner.webp)`}}>
                        <div className="container">
                            <div className="row row--30 align-items-center">
                                <div className="order-1 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                                    <div className="inner text-start">
                                        <h1 className="title color-white">
                                            We Analyze <strong>Your Business Process</strong> to Deliver the Best Solution
                                        </h1>
                                        <p className="description ">
                                            Our team ensures that every solution is aligned with your business goals, helping you increase efficiency, reduce costs, and enhance overall performance. 
                                        </p>
                                        <div className="button-group mt--30 mt_sm--20">
                                            <a className="btn-default btn-medium btn-icon btn-border" href="#contact">
                                                Get In Touch <i className="icon"><FiArrowRight /> <span></span></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="contact-service-box col-lg-6 order-2 order-lg-2 mt_md--50 mt_sm--50 mt_lg--30">
                                    <img  className='discount-label' src='/images/banner/discount-label.png' alt='discount rate' /> 
                                    <p className='subtitle'> Unlock Exclusive Discounts </p>
                                    <h3 className='text-left'>  <strong> Reach Out to </strong> Us Now! </h3>
                                    <ContactFormService formStyle="contact-form-1" />
                                </div>
                                <div className="shape-image">
                                    <img src="./images/banner/white-shape.svg" alt="Banner shap Code Aims" />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Start Service Area  */}
                    <div className="rn-service-area rn-section-gap" id='our-services'>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "What we can do for you"
                                        title = "Our <strong>Services</strong>"
                                     />
                                </div>
                            </div>
                            <ServiceMain serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                                textAlign = "text-start" />
                        </div>
                    </div>
                    {/* End Service Area  */}

                    <SlipThree id="wordpress" title={Data[0].title} description={Data[0].description} image={Data[0].image}/>
                    <SlipFour id="digital-art" title={Data[1].title} description={Data[1].description} image={Data[1].image}/>

                    {/* Start Elements Area  */}
                    <div className="rwt-advance-tab-area rn-section-gap">
                        <div className="container">
                            <div className="row mb--40">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Our Past Works"
                                        title = "Featured <strong>Works</strong>"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <AdvanceService />
                        </div>
                    </div>
                    {/* End Elements Area  */}
                    <Separator />
                    
                    <SlipThree id="character-design" title={Data[2].title} description={Data[2].description} image={Data[2].image}/>

                    {/* Start Accordion Area  */}
                     <div className="rn-accordion-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 offset-lg-1">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "FAQ"
                                        title = "Frequently Asked <strong>Questions.</strong>"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <div className="row mt--35 row--20">
                                <AccordionTwo customStyle="rn-accordion-01" />
                            </div>
                        </div>
                    </div>
                    {/* End Accordion Area  */}

                    <SlipThree id="security" title={Data[3].title} description={Data[3].description} image={Data[3].image}/>
                    <SlipFour id="migration" title={Data[4].title} description={Data[4].description} image={Data[4].image}/>

                    <Separator />

                    {/* Contact Form  */}
                    <div className="rwt-contact-area rn-section-gap" id='contact'>
                        <div className="container">
                            <div className="row mb--30">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Get In Touch"
                                        title = "Want To <strong>Work With Us?</strong>"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <div className="row mt--40 row--15" id='contact'>
                                <div className="col-lg-5 mt_md--30 mt_sm--30 pb--30">
                                    <img src={`/images/bg/contact-image.webp`} alt={` Code Aims`} style={{width: '100%', maxHeight: '550px', height: '100%', objectFit: 'cover', borderRadius: '10px'}} />
                                </div>
                                <div className="col-lg-7">
                                    <ContactForm formStyle="contact-form-1" />
                                </div>
                            </div>
                        </div>
                    </div>  
                    {/* Contact Form  */}
 
                    <FooterTwo />
                    <Copyright />
                </main>

        </>
    )
}

export default Services;
