import React  from 'react'
import SEO from "../common/SEO";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import HeaderOne from '../common/header/HeaderOne';
import Separator from '../elements/separator/Separator';
import CalltoActionSix from '../elements/calltoaction/CalltoActionSix';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import HeaderTopBar from '../common/header/HeaderTopBar';

const Portfolio = () => {

    return (
        <>
            <SEO title="Latest Works of Code Aims" />
            <main className="page-wrapper">
                <HeaderTopBar HeaderSTyle="header-transparent"/>
                <HeaderOne btnStyle=" round btn-icon" HeaderSTyle="header-transparent pt--20" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-650 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/portfolio-banner.webp)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">Latest Portfolio</span></h3>
                                    </div>
                                    <h1 className="title display-one strock">Our Works</h1>
                                    <p className="description ">  We invite you to explore our portfolio and see how we are making a difference in our communities.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <div className="main-content">
                    <div className="rwt-portfolio-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 section-title text-center">
                                    <p className={`subtitle`}>
                                        <span className="theme-gradient" >
                                            CHECK OUT SOME OF OUR MOST RECENT WORKS
                                        </span>
                                    </p>
                                    <h2 className="title w-600 mb--20"> Our <strong>Portfolio</strong></h2>
                                    <div className='p-20'>
                                        <Separator />
                                    </div>
                                </div>
                            </div>
                            <PortfolioOne Column="col-lg-4 col-md-6 col-sm-12 col-12 mt--30 portfolio" />
                        </div>
                    </div>
                </div>

                {/* call to action  */}
                <CalltoActionSix />

                {/* footer  */}
                <FooterTwo />
                <Copyright /> 
            </main>
        </>
    )
}
export default Portfolio;
