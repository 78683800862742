import React from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import FooterTwo from '../common/footer/FooterTwo';
import { FiArrowRight } from 'react-icons/fi';
import Copyright from '../common/footer/Copyright';
import HeaderTopBar from '../common/header/HeaderTopBar';
import InfiniteScrollAnimation from '../elements/scrollAnimation/InfiniteScrollAnimation';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import { EffectCoverflow  } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import ServiceOne from '../elements/service/ServiceOne';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ContactFormService from '../elements/contact/ContactFormService';
import SlipThree from '../elements/split/SlipThree';
import SlipFour from '../elements/split/SlipFour';

const TAGS = ['Professional Web Development Agency', 'E-Commerce Websites', 'Shopify', 'WordPress', 'Redesign Web', 'Affordable Digital Solutions Company', 'Web Development', 'Web Design', 'Responsive Design', 'Custom Web Development Services', 'User Experience', 'UX/UI', 'HTML5', 'CSS3', 'Graphic Design', 'Tech Solutions', 'Digital Solutions', 'Digital Art Services', 'Developer Community', 'Small Business Website Development', 'top-notch custom web development', 'Animation', 'Tailwind', 'BootStrap', 'Restaurant Site', 'SEO-Friendly Website Development'];

const slidesData = [
    { img: '/images/slider/codeaims-alex.jpg', alt: 'codeaims-alex' },
    { img: '/images/slider/codeaims-bocohstudios.jpg', alt: 'codeaims-bocohstudios' },
    { img: '/images/slider/codeaims-build.jpg', alt: 'codeaims-build' },
    { img: '/images/slider/codeaims-narjisvogue.jpg', alt: 'codeaims-narjisvogue' },
    { img: '/images/slider/codeaims-nftmarket.jpg', alt: 'codeaims-nftmarket' },
    { img: '/images/slider/codeaims-ppcpressure.jpg', alt: 'codeaims-ppcpressure' },
    { img: '/images/slider/codeaims-pressurewashing.jpg', alt: 'codeaims-pressurewashing' },
    { img: '/images/slider/codeaims-pro-cleaning.jpg', alt: 'codeaims-pro-cleaning' },
    { img: '/images/slider/codeaims-rezilla.jpg', alt: 'codeaims-rezilla' },
  ];

const BannerData = {
    image: "/images/bg/web-development-banner.webp",
    title: <><strong>Get a Professional Website</strong> Tailored to Your Business and Services.</>,
    description: <>If you want to stand out in a competitive niche, get your business to the next level, and reach a larger audience, you might need a great web presence for your business. <br></br> Code Aims are dedicated to helping your business grow with a website that truly delivers.</>,
    btnText: 'Our Works'
}

const Data = [
    {
        title : <><strong>Redesign</strong> Websites</>,
        description: 
        <>
            <h4 className="sub-title">Expert website redesign services to transform your business with a polished, <strong>professional online presence.</strong></h4>
            <p className='desc-18'><strong>Your Business Has Evolved — Has Your Website?</strong></p><p>Does this sound familiar? Many small and medium-sized businesses start with a basic website — often built from templates or with a little help from friends. It worked well in the beginning but has since become outdated or cluttered.</p><p>At Code Aims, we meet countless amazing companies delivering exceptional services and earning rave reviews from customers — but their websites don't reflect their brilliance.</p><p>If your website no longer represents your business, isn't it time for a change? Let us help you redesign and modernize your site to match your success.</p>
        </>,
        image: './images/split/redesign.jpg',
        btnText: 'Start a project',
    },
    {
        title : <>Web Design & <strong>Development</strong></>,
        description: 
        <>
            <h4 className="sub-title">Transform Your Business with a Professionally-Built Website and Clear, <strong>Compelling Messaging.</strong></h4>
            <p className=''>At Code Aims, we specialize in crafting custom websites that bring your vision to life. With extensive experience delivering hundreds of successful projects, we’ve partnered with businesses ranging from innovative start-ups to national charities, ensuring every website reflects the unique goals of our clients.</p><p className=''><strong>Our Approach: Your Goals First</strong></p><p className=''>Our process begins with understanding your business and goals. From crafting eye-catching designs to writing compelling headlines, our expert team is ready to deliver a website that drives results. As a small team with big talent, we provide end-to-end website design and development services tailored to your needs.</p><p className=''><strong>Why Choose Us?</strong></p>
            <ul> <li> <strong>Expertise Across Industries:</strong> From small businesses to large organizations, we create websites that resonate with diverse audiences.</li>  <li><strong>End-to-End Services:</strong> Design, development, and compelling content — everything is handled in-house.</li> <li><strong>Results-Driven:</strong> Every website is optimized to enhance user experience, drive engagement, and achieve your goals.</li> </ul>
        </>,
        image: './images/split/web-design.jpg',
    },
    {
        title : <><strong>WordPress</strong> Development</>,
        description: 
        <>
            <h4 className="sub-title">Transform Your Business with <strong>Expert WordPress Specialists.</strong></h4>
            <p className=''><strong>WordPress</strong> is the world's leading platform for building dynamic, scalable websites. By choosing WordPress, we provide our clients with robust, highly customizable websites tailored to meet their exact needs.</p><p className=''>We leverage WordPress as both a powerful framework and content management system, but that doesn’t mean your site will look generic. Our team specializes in <strong>customizing, developing, and extending the WordPress framework to create truly unique</strong>, bespoke websites.</p><p className=''>Many of our clients come to us with existing WordPress sites that need modifications. Whether they're dealing with off-the-shelf themes that aren't delivering the desired results or they've started customizing their site but hit a roadblock, we offer <strong>professional solutions</strong> to overcome these challenges and deliver the website they've always envisioned.</p>
        </>,
        image: './images/split/wordpress-development.jpg',
        btnText: 'Start a project',
    },
    {
        title : <><strong>UX / UI</strong></>,
        description: 
        <>
            <h4 className="sub-title">Expert UX/UI Design for Exceptional <strong>User Experiences.</strong></h4>
            <p className=''>At Code Aims, we specialize in creating seamless and engaging user experiences through expert UX/UI design. Our goal is to craft intuitive interfaces and well-structured user journeys that ensure your customers enjoy smooth interactions with your website or app.</p><p className=''><strong>UX Design:</strong> We begin by understanding your users and their needs. Our team conducts thorough research and testing to design user experiences that are both functional and delightful. From wireframes to user flows, every decision is made to enhance usability and satisfaction.</p><p className=''><strong>UI Design:</strong> Our UI designers focus on aesthetics and interaction, ensuring your website or app not only looks great but also feels effortless to navigate. With an eye for detail, we create visually appealing designs that align with your brand and attract users to engage with your content.</p><p>By integrating UX and UI design principles, we help you convert visitors into loyal customers, providing them with a smooth and enjoyable experience every time they visit your site or use your app. Let us design a digital experience that keeps your users coming back.</p>
        </>,
        image: './images/split/ux-ui.jpg',
    },
];

const WebDevelopment = () => {

    return (
        <>
            <SEO title="Affordable Web Desgin & Development Services" />
            <main className="page-wrapper">
                <HeaderTopBar HeaderSTyle="header-transparent"/>
                <HeaderOne btnStyle="btn-icon round" HeaderSTyle="header-transparent pt--20" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-6 height-1020" style={{backgroundImage: `url(${BannerData.image})`}}>
                    <div className="container">
                        <div className="row row--30 align-items-center">

                            <div className="order-lg-1 order-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner text-start">
                                    <h1 className="title color-white">
                                        {BannerData.title}
                                    </h1>
                                    <p className="description ">
                                        {BannerData.description}
                                    </p>
                                    <div className="button-group mt--30 mt_sm--20">
                                        <Link className="btn-default btn-medium btn-icon btn-border" to="/portfolio">
                                            {BannerData.btnText}
                                        <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </div>
                            </div>

                            <div className="contact-service-box order-lg-2 order-2 col-lg-5 mt_md--50 mt_sm--50 mt_lg--30">
                                <img  className='discount-label' src='/images/banner/discount-label.png' alt='discount rate' /> 
                                <p className='subtitle'> Unlock Exclusive Discounts </p>
                                <h3 className='text-left'>  <strong> Reach Out to </strong> Us Now! </h3>
                                <ContactFormService formStyle="contact-form-1" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <div className="rn-service-area rn-section-gap">
                    <InfiniteScrollAnimation TAGS={TAGS} />
                </div>

                <SlipThree id="redesign" title={Data[0].title} description={Data[0].description} image={Data[0].image} btnText={Data[0].btnText} />
                <SlipFour id="web-design" title={Data[1].title} description={Data[1].description} image={Data[1].image} btnText={Data[1].btnText} />

                <div className="ca-section-gap1" style={{ width: '100%', margin: 'auto', padding: '50px 0' }}>
                    <Swiper
                        modules={[EffectCoverflow]}
                        effect="coverflow"
                        spaceBetween={0}
                        grabCursor={true}
                        slidesPerView={1.5}
                        centeredSlides={true}
                        loop={true}
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: true,
                        }}
                        navigation
                        pagination={{ clickable: true }}
                        style={{ padding: '20px 0' } }
                        
                        breakpoints={{
                            0: {
                                slidesPerView: 1.4,
                                spaceBetween: 0,
                            },
                            640: {
                                slidesPerView: 1.5,
                                spaceBetween: 0,
                            },
                            768: {
                                slidesPerView: 1.8,
                                spaceBetween: 10,
                            },
                            1024: {
                                slidesPerView: 1.8,
                                spaceBetween: 30,
                            },
                        }}
                    >
                        {slidesData.map((slide, index) => (
                        <SwiperSlide key={index}>
                            <img
                            src={slide.img}
                            alt={slide.alt}
                            style={{ width: '100%', height: '100%' }}
                            />
                        </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className='container'>
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    sectionStyle="section-title-3"
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = ""
                                    title = "Our websites are designed to <strong>improve bounce rates</strong> and increase lead generation."
                                    description = ""
                                    />
                            </div>
                        </div>
                    </div>
                </div>

                <SlipThree id="wordpress" title={Data[2].title} description={Data[2].description} image={Data[2].image} btnText={Data[2].btnText} />
                <SlipFour id="ux-ui" title={Data[3].title} description={Data[3].description} image={Data[3].image} btnText={Data[3].btnText} />

                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Key Benefits"
                                    title = "Our Expertise <br> <strong>Your Key Benefits</strong>"
                                    description = ""
                                    />
                            </div>
                        </div>
                        <ServiceOne 
                            serviceStyle="service__style--1 radius mt--25 rbt-border"
                            textAlign="text-start"/>
                    </div>
                </div>
                {/* End Service Area  */}

                <div className="rwt-callto-action-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <CalltoActionFive />
                            </div>
                        </div>
                    </div>
                </div>

                {/* footer  */}
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default WebDevelopment;
