import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const AdvanceTabOne = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 450);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 450);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    return (
        <div>
            <Tabs>
                <div className="row row--30">
                    <div className="order-1 order-lg-1 col-lg-4 col-md-12 col-sm-12 col-12 ">
                        <div className="advance-tab-button advance-tab-button-1">
                            <TabList className="tab-button-list">
                                <Tab>
                                    <div className="tab-button">
                                        <h3 className="title">
                                            {isMobile ? 'WEB DEVELOPMENT' : 'WEB DEVELOPMENT SERVICES'}
                                        </h3>
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h3 className="title">
                                            {isMobile ? 'DESIGN SERVICES' : 'DESIGN SERVICES'}
                                        </h3>
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h3 className="title">
                                            {isMobile ? 'BUSINESS PACKAGES' : 'BUSINESS PACKAGES'}
                                        </h3>
                                    </div>   
                                </Tab>
                            </TabList>
                        </div>
                    </div>
                    <div className="order-2 order-lg-2 col-lg-8 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30">
                        <div className="advance-tab-content advance-tab-content-1">
                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <p className='description'><strong>HTML5 - CSS3 - JavaScript - Jquery - Bootstrap - Tailwind - PHP - MySQL - React</strong></p>
                                        <ul>
                                            <li>
                                                <Link to='/web-development'>Website Development</Link>  
                                            </li>
                                            <li>
                                                <Link to='/web-development'>Landing and Promo sites</Link>
                                            </li>
                                            <li>
                                                <Link to='/web-development'>Coporate Website</Link>
                                            </li>
                                            <li>
                                                <Link to='/web-development'>Startup Website</Link>
                                            </li>
                                            <li>
                                                <Link to='/web-development'>E-commerce</Link>
                                            </li>
                                            <li>
                                                <Link to='/web-development'>WordPress Development</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </TabPanel>
                            
                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <p className='description'><strong>Photoshop - Illustrator - Figma - Sketch</strong></p>
                                        <ul>
                                            <li>
                                                <Link to='/services'>Responsive Web Design</Link>
                                            </li>
                                            <li>
                                                <Link to='/services'>UX / UI Design</Link>
                                            </li>
                                            <li>
                                                <Link to='/services'>Logo & Branding Design</Link>
                                            </li>
                                            <li>
                                                <Link to='/services'>Digital Art Services</Link>
                                            </li>
                                            <li>
                                                <Link to='/services'>2D Character & Digital Enviroment Design</Link>
                                            </li>
                                            <li>
                                                <Link to='/services'>Thumbnail & Banner Ad Design</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <p className='description'> <strong> WordPress - Woocommerce - Bootstrap - Tailwind </strong></p>
                                        <ul>
                                            <li>
                                                <Link to='/web-development'>Custom Web Development</Link>
                                            </li>
                                            <li>
                                                <Link to='/web-development'>Custom Landing Page</Link>
                                            </li>
                                            <li>
                                                <Link to='/web-development'>Custom WordPress Development Experts</Link>
                                            </li>
                                            <li>
                                                <Link to='/web-development'>Small Business Website Development</Link>
                                            </li>
                                            <li>
                                                <Link to='/web-development'>Video Editing</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </Tabs>
        </div>
    )
}
export default AdvanceTabOne;