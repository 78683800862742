import React from 'react';
import SEO from "../common/SEO";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import HeaderTwo from '../common/header/HeaderTwo';
import HeaderTopNews from '../common/header/HeaderTopNews';
import NewsletterOne from '../common/footer/NewsletterOne';
import FooterThree from '../common/footer/FooterThree';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import PortfolioDesign from '../elements/portfolio/PortfolioDesign';

const Design = () => {
    return (
        <>
            <SEO title="Our Works" />
            <main className="page-wrapper">
                <HeaderTopNews  />
                <HeaderTwo btnStyle="btn-icon round" HeaderSTyle="header-transparent pt--20" />

                {/* Start Brand Area  */}
                <div className="cd-design-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle   
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Latest Portfolio"
                                    title = "Our Works"
                                    description = "We invite you to explore our portfolio and see how we are making a difference in our communities."
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 mt--40">
                            <PortfolioDesign />
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}

                <div className="rwt-callto-action-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <CalltoActionFive />
                            </div>
                        </div>
                    </div>
                </div>

                <NewsletterOne newsletetrStyle="rn-newsletter-default " extraClass="border-top-only" />
                <FooterThree />
            </main>
        </>
    )
}
export default Design;