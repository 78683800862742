import PropTypes from "prop-types";
import React from 'react'
import { HelmetProvider, Helmet } from "react-helmet-async";

const SEO = ( 
        { title = "Home Page", description ="Code Aims - offers a wide range of topics that are sure to help everyone grow and succeed.", image = `/social-logo.jpg`, url = window.location.href } ) => {
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title} - Code Aims</title>
                <meta name="robots" content="index, follow" />
                <meta name="description" content={description} />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={image} />
                <meta property="og:url" content={url} />
                <meta property="og:type" content="article" />
                
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={image} />
                
                <link rel="canonical" href={url} />
            </Helmet>
        </HelmetProvider>
    )
}
SEO.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.string,
    url: PropTypes.string,
};
export default SEO;